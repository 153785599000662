import { MetafieldInput } from '@unlikelystudio/ecommerce-typed/types/shopify-admin'
import { UnlikelyError } from '@unlikelystudio/provider-shopify'

enum QueryActions {
  REGISTER = 'register',
  LOGIN = 'login',
  PROFILE = 'profile',
  ORDERS = 'orders',
  ADDRESSES = 'addresses',
  UPDATE_PASSWORD = 'update-password',
  REQUEST_RESET_PASSWORD = 'request-reset-password',
  RESET_PASSWORD = 'reset-password',
  ENABLE_ACCOUNT = 'enable-account',
  NEWSLETTER = 'newsletter',
  CONTACT = 'contact',
  RECAPTCHA = 'verify-recaptcha',
  GIFT_SUGGESTION = 'gift-suggestion',
  APPOINTMENT_AVAILABILITIES = 'get-appointment-availabilities',
  CREATE_APPOINTMENT = 'create-appointment',
  COUNTRIES_LIST = 'get-countries-list',
  SUBMIT_DYNAMIC_FORM = 'submit-dynamic-form',
  GET_PRODUCTS = 'products/get',
}

export enum QueryMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type QueryPayload = Record<
  string,
  string | number | object | boolean | MetafieldInput[]
>

interface QueryParams {
  store?: string
  payload?: QueryPayload
  accessToken?: string
}

interface GenerateQueryParams extends QueryParams {
  action: QueryActions | string
  method: QueryMethods
}

async function generateQuery({
  store,
  action,
  payload,
  accessToken,
  method,
}: GenerateQueryParams) {
  const hasBody = method !== QueryMethods.GET

  const req = await fetch(`/api/${action}`, {
    headers: {
      'Content-Type': 'application/json',
      ...(store && { 'X-Unlikely-Store': store }),
      ...(accessToken && { Authorization: accessToken }),
    },
    method,
    ...(hasBody && { body: JSON.stringify(payload) }),
  })

  const res = await req.json()

  //TODO: send relevant UnlikelyError (could be different than a Shopify error)
  if (res.payload) throw new UnlikelyError('shopify', res.payload)

  return res
}

export async function register(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.REGISTER,
    method: QueryMethods.POST,
  })
}

export async function login(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.LOGIN,
    method: QueryMethods.POST,
  })
}

export async function getCustomer(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.PROFILE,
    accessToken: params.accessToken,
    method: QueryMethods.GET,
  })
}

export async function getOrders(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ORDERS,
    accessToken: params.accessToken,
    method: QueryMethods.GET,
  })
}

export async function getAddresses(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.GET,
  })
}

export async function updateCustomer(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.PROFILE,
    accessToken: params.accessToken,
    method: QueryMethods.PUT,
  })
}

export async function updateAddress(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.PUT,
  })
}

export async function updatePassword(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.UPDATE_PASSWORD,
    accessToken: params.accessToken,
    method: QueryMethods.PUT,
  })
}

export async function requestResetPassword(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.REQUEST_RESET_PASSWORD,
    method: QueryMethods.POST,
  })
}

export async function resetPassword(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.RESET_PASSWORD,
    method: QueryMethods.POST,
  })
}

export async function createAddress(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.POST,
  })
}

export async function deleteAddress(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.DELETE,
  })
}

export async function registerToNewsletter(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.NEWSLETTER,
    method: QueryMethods.POST,
  })
}

export async function enableAccount(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ENABLE_ACCOUNT,
    method: QueryMethods.POST,
  })
}

export async function submitContactForm(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.CONTACT,
    method: QueryMethods.POST,
  })
}

export async function submitDynamicForm(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.SUBMIT_DYNAMIC_FORM,
    method: QueryMethods.POST,
  })
}

export async function submitGiftSuggestion(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.GIFT_SUGGESTION,
    method: QueryMethods.POST,
  })
}

export const verifyRecaptcha = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.RECAPTCHA,
    method: QueryMethods.POST,
  })
}

export const getAppointmentAvailabilities = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.APPOINTMENT_AVAILABILITIES,
    method: QueryMethods.POST,
  })
}

export const createAppointment = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.CREATE_APPOINTMENT,
    method: QueryMethods.POST,
  })
}

export const getProducts = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.GET_PRODUCTS,
    method: QueryMethods.POST,
  })
}

export const getCountriesList = async (params: QueryParams) => {
  return await generateQuery({
    action: `${QueryActions.COUNTRIES_LIST}?locale=${params?.payload?.locale}`,
    method: QueryMethods.GET,
  })
}
