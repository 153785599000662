import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useTracking } from '~/providers/TrackingProvider'

import useProfile from '~/hooks/account/useGetProfile'
import useCurrency from '~/hooks/useCurrency'

import { ProductTrackingData } from '~/data/serialize-product-tracking-data'

export type EcommerceTrackingData = {
  ecommerce: {
    values?: number
    items?: ProductTrackingData
  }
}
declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

export default function useGTMTracking() {
  const { data: customer } = useProfile()
  const router = useRouter()
  const tracking = useTracking()
  const currency = useCurrency()

  const pushToDataLayer = (event: any) => {
    window?.dataLayer?.push({ ...event })
  }

  const userData = (id?: string) => ({
    user_data: {
      user_id: id ?? customer?.id ?? null,
    },
  })

  const getPageData = (): { page_categoy_1: string } => {
    const route = router.route

    switch (route) {
      case '/':
        return {
          page_categoy_1: 'home',
        }
      case '/products/[uid]':
      case '/product-variations/[uid]':
        return {
          page_categoy_1: 'product page',
        }
      case '/account':
        return {
          page_categoy_1: 'account',
        }
      case '/categories/[uid]':
        return {
          page_categoy_1: 'page listing',
        }
      case '/search':
        return {
          page_categoy_1: 'search',
        }
      default:
        return {
          page_categoy_1: 'edito',
        }
    }
  }

  const getProductPayload = (items): EcommerceTrackingData => {
    return {
      ecommerce: {
        ...(items && {
          values: items?.reduce((acc, curr) => {
            return acc + curr?.price
          }, 0),
        }),
        items: items?.map((item) => ({
          ...item,
          currency: currency?.toUpperCase(),
        })),
      },
    }
  }

  const eventReducer = (event: string, eventPayload?: any) => {
    switch (event) {
      case TRACKING_EVENTS.PAGE_VIEW:
        pushToDataLayer({
          event,
          page: {
            ...getPageData(),
            ...userData(),
          },
        })
        break
      case TRACKING_EVENTS.VIEW_ITEM:
      case TRACKING_EVENTS.VIEW_CART:
      case TRACKING_EVENTS.REMOVE_FROM_CART:
      case TRACKING_EVENTS.ADD_TO_CART:
      case TRACKING_EVENTS.VIEW_ITEM_LIST:
      case TRACKING_EVENTS.BEGIN_CHECKOUT:
        pushToDataLayer({ ecommerce: null })
        pushToDataLayer({
          event,
          ...getProductPayload(eventPayload),
        })
        break
      case TRACKING_EVENTS.SELECT_ITEM:
        pushToDataLayer({ ecommerce: null })
        pushToDataLayer({
          event,
          ...getProductPayload(
            eventPayload?.map((item) => ({
              ...item,
              item_list_name: router?.query?.uid ?? 'home',
            })),
          ),
        })
        break
      case TRACKING_EVENTS.LOGIN:
      case TRACKING_EVENTS.SIGN_UP:
        pushToDataLayer({ event, ...userData(eventPayload?.id) })
        break
      case TRACKING_EVENTS.SIZE_CLIC:
      case TRACKING_EVENTS.SIZE_SELECTED:
      case TRACKING_EVENTS.SIZE_VALIDATED:
      case TRACKING_EVENTS.FILTER_APPLIED:
      case TRACKING_EVENTS.INTERNAL_SEARCH:
      case TRACKING_EVENTS.SIZE_GUIDE_DOWNLOAD:
        pushToDataLayer({ event, ...eventPayload })
        break
      case TRACKING_EVENTS.CONTACT_FORM:
        pushToDataLayer({ event: 'generate_lead', lead_type: 'contact_form' })
        break
      case TRACKING_EVENTS.NEWSLETTER:
        pushToDataLayer({ event: 'generate_lead', lead_type: 'newsletter' })
        break
      case TRACKING_EVENTS.APPOINTMENT:
        pushToDataLayer({
          event: 'generate_lead',
          lead_type: 'meeting_booked',
          ...eventPayload,
        })
        break
      default:
        return
    }
  }

  useEffect(() => {
    tracking.on('*', eventReducer)
    return () => {
      tracking.off('*', eventReducer)
    }
  })
}
