import { createUrl, PathRegExp } from '@marvinh/path-to-regexp'
import { DEFAULT_LOCALE, SINGLE_ROUTES } from '~/lib/constants'

import pathsMapping from 'config/paths-mapping.json'

import GlobalLocale from '~/utils/global-locale'

export interface LinkResolverDocParams {
  type: string
  uid: string
  lang: string
  link_type?: string
  url?: string
}

export default function linkResolver(
  doc: LinkResolverDocParams,
  withoutLocale?: boolean,
  withDocLang?: boolean,
): string {
  if (doc?.link_type === 'Web') {
    return doc?.url
  }

  const locale = withDocLang ? doc?.lang : GlobalLocale.locale ?? doc?.lang
  const pathString = pathsMapping?.[doc?.type]?.[locale]?.source
  const pathRegExp = pathString ? new PathRegExp(pathString) : null
  const localeParam =
    locale === DEFAULT_LOCALE || withoutLocale ? '' : `/${locale}`

  switch (doc?.type) {
    case 'home_page':
    case 'global':
      return `${process.env.NEXT_PUBLIC_ROOT_URL}${localeParam}`
    case 'product':
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp, {
            uid: doc?.uid,
          })}`
        : `${localeParam}/${doc?.type}s/${doc?.uid}`
    case 'product_variation':
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp, {
            uid: doc?.uid,
          })}`
        : `${localeParam}/product-variations/${doc?.uid}`
    case 'half_product':
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp, {
            uid: doc?.uid,
          })}`
        : `${localeParam}/half-products/${doc?.uid}`
    case 'category':
      return `${localeParam}/categories/${doc?.uid}`
    case 'all_categories':
      return `${localeParam}/categories`
    case 'collection':
      return `${localeParam}/collections/${doc?.uid}`
    case 'all_collections':
      return `${localeParam}/collections`
    case SINGLE_ROUTES.search.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${doc?.type}`
    case SINGLE_ROUTES.login.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${SINGLE_ROUTES.login.href.substring(1)}`
    case SINGLE_ROUTES.register.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${SINGLE_ROUTES.register.href.substring(1)}`
    case SINGLE_ROUTES.forgotPassword.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${SINGLE_ROUTES.forgotPassword.href.substring(1)}`
    case SINGLE_ROUTES.resetPassword.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${SINGLE_ROUTES.resetPassword.href.substring(1)}`
    case SINGLE_ROUTES.enableAccount.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${SINGLE_ROUTES.enableAccount.href.substring(1)}`
    case SINGLE_ROUTES.account.type:
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/${SINGLE_ROUTES.account.href.substring(1)}`
    case 'stores':
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/stores`
    case 'appointment_page':
      return pathString
        ? `${localeParam}/${createUrl(pathRegExp)}`
        : `${localeParam}/appointment`
    case 'service':
      return `${localeParam}/service/${doc?.uid}`
    case 'country':
      return `${localeParam}`
    case 'article_page':
      return `${localeParam}/journal/${doc?.uid}`
    case 'journal_page':
      return `${localeParam}/journal`
    case 'universal_page':
      return `${localeParam}/${doc?.uid}`
    default:
      return `${localeParam}/${doc?.uid}`
  }
}
