import classnames from 'classnames/bind'
import React, { ReactChild, useRef } from 'react'

import { useSetStyle } from '@unlikelystudio/react-gsap-hooks'
import {
  useIsomorphicLayoutEffect,
  useOnResize,
  useSpring,
} from '@unlikelystudio/react-hooks'

import Accordion from '~/components/Accordion'
import AccordionPanel from '~/components/Accordion/AccordionPanel'
import BottomButtons from '~/components/BottomButtons'
import Button from '~/components/BottomButtons/Button'
import LangSwitcher from '~/components/LangSwitcher'
import { MobileHeaderProps } from '~/components/Navigation/Mobile/MobileHeader'
import MobilePanelSection from '~/components/Navigation/Mobile/MobilePanelSection'
import NavigationLink from '~/components/Navigation/common/NavigationLink'
import { PanelContact } from '~/components/Panels'
import { MinusIcon, PlusIcon } from '~/components/icons'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'

import { customEaseOut } from '~/utils/easings'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface MobilePanelProps {
  className?: string
  contentClassName?: string
  headerProps: MobileHeaderProps
  children?: ReactChild | ReactChild[]
}

export default function MobilePanel({
  className,
  contentClassName,
  children,
  headerProps,
}: MobilePanelProps) {
  const { isOpen, toggleMenu } = headerProps
  const panelRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const sectionTitleStyle = useStyle({ textPreset: 'cta-12' })
  const bottomLinkStyle = useStyle({ textPreset: 'cta-10' })
  const setPanelStyle = useSetStyle(panelRef)
  const setWrapperStyle = useSetStyle(wrapperRef)
  const { add } = usePanel()

  const [setProgressSpring] = useSpring({
    config: { interpolation: 'basic', friction: 8, precisionStop: 0.000001 },
    progress: 0,

    onUpdate: ({ progress }, { progress: prevProgress }) => {
      if (progress !== prevProgress) {
        const easedProgress =
          progress > prevProgress ? progress : customEaseOut(progress)

        const x = 100 - easedProgress * 100

        setPanelStyle({ x: `${-x}%`, force3D: true })
        setWrapperStyle({ x: `${x}%`, force3D: true })
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    const progress = isOpen ? 1 : 0
    setProgressSpring({ progress })
  }, [isOpen])

  useBodyScrollLock(isOpen, contentRef?.current, 'mobile-menu')

  useOnResize(() => {
    if (isOpen) toggleMenu()
  })

  return (
    <div ref={panelRef} className={cx(className, css.Panel)}>
      <div ref={wrapperRef}>
        {children}
        <div ref={contentRef} className={cx(contentClassName, css.content)}>
          <Accordion>
            {headerProps?.headerLeft?.map((section, index) => (
              <AccordionPanel
                customHandleClick={section?.isDirectLink ? () => null : null}
                key={`accordionPanel${index}`}
                index={index}
                restoreIndex={!isOpen}
                head={(isActive: boolean) => {
                  return section?.isDirectLink ? (
                    <NavigationLink
                      linkClassName={cx(
                        css.directLink,
                        css.sectionTitle,
                        sectionTitleStyle,
                      )}
                      type="menuAccordion"
                      link={section?.link}
                      inlineCtaProps={{
                        withUnderLine: false,
                      }}
                    />
                  ) : (
                    <div className={cx(css.sectionTitle)}>
                      <p className={cx(sectionTitleStyle, css.link)}>
                        {section?.link?.children}
                      </p>

                      {isActive ? <MinusIcon /> : <PlusIcon />}
                    </div>
                  )
                }}
                body={
                  <div>
                    {section?.rows?.map((row, id) => (
                      <MobilePanelSection
                        key={`panelSection${id}`}
                        toggleMenu={toggleMenu}
                        {...(section?.isDirectLink
                          ? { title: null, links: null, bottomLink: null }
                          : {
                              title: row?.title,
                              links: row?.links,
                              bottomLink: row?.bottomLink,
                            })}
                      />
                    ))}
                  </div>
                }
              />
            ))}
          </Accordion>
          <LangSwitcher />
        </div>
        <BottomButtons>
          {[headerProps?.contactLink]?.map(
            (link, index) =>
              link && (
                <Button
                  key={`bottomStickyLink${index}`}
                  className={cx(css.button, bottomLinkStyle)}
                  link={index === 0 ? null : link}
                  text={index === 0 ? link?.children : null}
                  onClick={(e) => {
                    if (index === 0) {
                      e.preventDefault()
                      add({ component: <PanelContact /> })
                    }
                  }}
                />
              ),
          )}
        </BottomButtons>
      </div>
    </div>
  )
}

MobilePanel.defaultProps = {}
